import itemFields, {
  ItemField,
  itemFieldGroups,
  itemSchema,
} from "@/config/item";
import { FC, useCallback, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { RESTPostAPIItemBody } from "@/types/points";
import ImageUpload from "@/components/ImageUpload";
import Stepper from "@/components/Stepper";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useGuild } from "@/api/useGuild";
import { useAuthState } from "@/api/useAuthState";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

type ItemCreationModalProps = {
  guildID: string;
};

const ItemCreationModel: FC<ItemCreationModalProps> = (props) => {
  const { guildID } = props;

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const { data: auth } = useAuthState();
  const { data: guild } = useGuild(guildID);
  const [open, setOpen] = useState(false);

  const premiumTier = guild?.premium?.tier ? 3 : auth?.premium?.tier;

  const roles = useMemo(() => {
    return (
      guild?.roles
        .filter((role) => role.name !== "@everyone" && !role.managed)
        .map((role) => ({
          value: role._id,
          label: role.name,
        })) || []
    );
  }, [guild]);

  const form = useForm<z.infer<typeof itemSchema>>({
    resolver: zodResolver(itemSchema),
  });

  const itemAmountField = form.watch("amount");

  const createItem = useMutation({
    mutationFn: async ({
      guildID,
      item,
    }: {
      guildID: string;
      item: RESTPostAPIItemBody;
    }) => {
      if (!token) return;
      const res = await api.post(`/guilds/${guildID}/shop`, item, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const handleSubmit = useCallback(
    (values: z.infer<typeof itemSchema>) => {
      createItem.mutate({ guildID, item: values });
      setOpen(false);
    },
    [guildID, createItem]
  );

  const [currentStep, setCurrentStep] = useState(0);

  const grouppedFields = useMemo(() => {
    const fields = itemFields.reduce(
      (acc, field) => {
        if (!acc[field.group]) {
          acc[field.group] = [];
        }
        acc[field.group].push(field);
        return acc;
      },
      {} as Record<string, ItemField[]>
    );
    return Object.entries(fields);
  }, []);

  const isLastStep = currentStep === grouppedFields.length - 1;

  const groupErrors = useMemo(() => {
    const formErrors = form.formState.errors;
    const errors = grouppedFields.map(([group, itemFields]) => {
      const groupErrors = itemFields.map((field) => {
        if (!field.premium || premiumTier) {
          const error = formErrors[field.id];
          return !!error;
        }
        return false;
      });
      const errorCount = groupErrors.filter((error) => error).length;

      return {
        group,
        errorCount,
      };
    });
    return errors.reduce(
      (acc, error) => {
        acc[error.group] = error.errorCount;
        return acc;
      },
      {} as Record<string, number>
    );
  }, [grouppedFields, premiumTier]);

  const steps = useMemo(() => {
    return grouppedFields.map(([group, _itemFields]) => {
      const groupDetails = itemFieldGroups[group];
      const groupErrorCount = groupErrors[group];
      return {
        name: groupDetails.name,
        description: groupDetails.description,
        errorCount: groupErrorCount,
      };
    });
  }, []);

  const currentStepFields = grouppedFields[currentStep][1];

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="sm" className="mr-2">
          <FontAwesomeIcon icon={faPlus} /> Create Item
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[690px] max-w-3xl flex flex-col">
        <SheetHeader>
          <SheetTitle>Create Item</SheetTitle>
          <SheetDescription>
            <Stepper
              steps={steps}
              currentStep={currentStep}
              onStepClick={setCurrentStep}
            />
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="flex flex-col gap-2 min-h-0 flex-grow"
          >
            <div className="flex flex-col gap-4 flex-grow">
              {currentStepFields.map((itemField) => {
                if (itemField.id === "role" && itemAmountField > 1) {
                  return (
                    <div key={itemField.id}>
                      <FormItem>
                        <FormLabel>{itemField.name}</FormLabel>
                        <FormDescription>
                          {itemField.description}
                        </FormDescription>
                        <FormMessage>
                          This field is disabled when amount is more than 1
                        </FormMessage>
                      </FormItem>
                    </div>
                  );
                }
                if (!itemField.premium || premiumTier) {
                  return (
                    <FormField
                      key={itemField.id}
                      control={form.control}
                      name={itemField.id}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {itemField.name}
                            {itemField.required ? (
                              <span className="text-red-400 ml-1">*</span>
                            ) : null}
                          </FormLabel>
                          <FormDescription>
                            {itemField.description}
                          </FormDescription>
                          {itemField.type === "string" ||
                          itemField.type === "number" ? (
                            <FormControl>
                              <Input type={itemField.type} {...field} />
                            </FormControl>
                          ) : itemField.type === "textarea" ? (
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                          ) : itemField.type === "select" ? (
                            <Select
                              onValueChange={field.onChange}
                              value={
                                typeof field.value === "string"
                                  ? field.value
                                  : undefined
                              }
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {roles.map((role) => (
                                  <SelectItem
                                    key={role.value}
                                    value={role.value}
                                  >
                                    {role.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          ) : itemField.type === "image" ? (
                            <ImageUpload
                              noURL
                              className="h-auto"
                              value={
                                typeof field.value === "string"
                                  ? field.value
                                  : ""
                              }
                              onChange={(value) =>
                                field.onChange({
                                  target: {
                                    value,
                                    name: field.name,
                                  },
                                })
                              }
                            />
                          ) : null}
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  );
                }
              })}
              {!premiumTier && (
                <div>
                  Unlock more with premium!{" "}
                  <a href="https://points.bot/premium">Learn More.</a>
                </div>
              )}
            </div>

            <SheetFooter>
              <Button
                className="mt-auto p-2 rounded-lg w-full text-white"
                type={isLastStep ? "submit" : "button"}
                onClick={
                  isLastStep ? undefined : () => setCurrentStep(currentStep + 1)
                }
              >
                {isLastStep ? "Create Item" : "Next"}
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

export default ItemCreationModel;
