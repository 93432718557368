import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/pro-solid-svg-icons";

type RecoverProps = {
  guildID: string;
  itemID: string;
};

const RecoverButton: FC<RecoverProps> = (props) => {
  const { guildID, itemID } = props;
  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const recoverItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}/recover`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <Button
      variant="secondary"
      onClick={() => {
        recoverItem.mutate({
          guildID,
          itemID,
        });
      }}
      className="w-full"
    >
      <FontAwesomeIcon icon={faUndo} />
      Recover
    </Button>
  );
};

export default RecoverButton;
