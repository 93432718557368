import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { useAuthState } from "@/api/useAuthState";
import { parseAvatar } from "@/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useSetAtom } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { API_URL } from "@/config/network";
import {
  faArrowRightFromBracket,
  faHammer,
  faHouseBuilding,
} from "@fortawesome/pro-solid-svg-icons";

const premiumTier = ["", "Basic", "Plus", "Ultra"];

const Dropdown = () => {
  const { data: auth } = useAuthState();
  const navigate = useNavigate();
  const setToken = useSetAtom(pointsTokenAtom);
  if (!!auth) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          {auth?.avatar ? (
            <img
              className="w-8 h-8 rounded-full cursor-pointer hover:shadow-xl"
              src={parseAvatar(auth?._id, auth?.avatar, 32)}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-slate-500 cursor-pointer hover:shadow-xl flex items-center justify-center">
              {auth?.username?.at(0) ?? ""}
            </div>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="bg-card shadow-2xl p-2 min-w-48"
        >
          {!!auth?.premium?.tier ? (
            <DropdownMenuLabel>
              Premium {premiumTier[auth?.premium?.tier ?? 0]}
            </DropdownMenuLabel>
          ) : null}
          <DropdownMenuItem onClick={() => navigate("/guilds")}>
            <FontAwesomeIcon icon={faHouseBuilding} />
            Servers
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          {!!auth.permission && (
            <>
              <DropdownMenuLabel className="uppercase text-[10px] pb-0 opacity-50">
                <strong>Role:</strong> {auth.permission}
              </DropdownMenuLabel>
              <DropdownMenuItem onClick={() => navigate("/admin")}>
                <FontAwesomeIcon icon={faHammer} />
                Admin Panel
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuItem
            onClick={() => {
              setToken(null);
              navigate("/");
            }}
            className="text-red-500 focus:bg-red-500/10 focus:text-red-600"
          >
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
  return (
    <a
      style={{
        color: "#00a7e3",
      }}
      href={`${API_URL}/auth/login`}
    >
      Login
    </a>
  );
};

const Navbar = () => {
  const { data: auth } = useAuthState();

  return (
    <>
      <div className="h-10 border-none bg-transparent">
        <div className="container relative mx-auto h-full w-full py-8 flex items-center justify-between">
          <div className="flex items-center justify-between w-full mr-6">
            <div className="flex items-center gap-4">
              <Link to="/">
                <img
                  draggable="false"
                  src={`/images/logo/${
                    !!auth?.premium?.tier ? "PremiumLarge" : "DefaultLarge"
                  }.svg`}
                  className="w-8 h-8"
                  alt="Points"
                />
              </Link>
            </div>
            <div className="flex items-center gap-6 absolute mx-auto left-1/2 transform -translate-x-1/2">
              <Link to="/" className="text-sm opacity-60">
                Features
              </Link>
              <Link to="/premium" className="text-sm opacity-60">
                Premium
              </Link>
              <Link to="/docs" className="text-sm opacity-60">
                Documentation
              </Link>
            </div>
            <div className="flex items-center">
              <Link to="/discord">
                <FontAwesomeIcon icon={faDiscord} />
              </Link>
            </div>
          </div>
          <Dropdown />
        </div>
      </div>
    </>
  );
};

export default Navbar;
