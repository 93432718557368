import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import Empty from "../Empty";
import { useState, FC } from "react";
import { useParams } from "react-router-dom";
import Item from "../shop/Item";
import { useGuild } from "@/api/useGuild";
import { useCurrentMember } from "@/api/useCurrentMember";
import { useShop } from "@/api/useShop";
import { useAtom, useAtomValue } from "jotai";
import { pointsTokenAtom, selectedMemberAtom } from "@/stores";
import { useMember } from "@/api/useMember";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import Select from "@/components/Select";
import { Badge } from "@/components/ui/badge";

type MemberProps = {
  memberID: string;
};

const Member: FC<MemberProps> = (props) => {
  const { memberID } = props;
  const { guildID } = useParams<{ guildID: string }>();

  const [selectedMember, setSelectedMember] = useAtom(selectedMemberAtom);
  const token = useAtomValue(pointsTokenAtom);

  const { data: member, isLoading } = useMember(guildID, memberID);
  const { data: guild } = useGuild(guildID);
  const { data: currentMember } = useCurrentMember(guildID);
  const { data: shop } = useShop(guildID);

  const [view, setView] = useState(false);
  const [itemSelected, setItemSelected] = useState<string | null>();

  const queryClient = useQueryClient();

  const addItem = useMutation({
    mutationFn: async ({
      guildID,
      memberID,
      itemID,
    }: {
      guildID: string;
      memberID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/members/${memberID}/${itemID}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["member", guildID, memberID],
      });
    },
  });

  if (!guildID) return <></>;

  return (
    <>
      <SheetHeader>
        <SheetTitle className="font-semibold text-2xl">
          {isLoading
            ? "Loading..."
            : `${member?.user?.username}${
                member?.user?.discriminator !== "0"
                  ? `#${member?.user?.discriminator}`
                  : ""
              }`}{" "}
        </SheetTitle>
        <SheetDescription>
          {!view && (
            <div className="gap-2 flex items-center">
              <Badge
                variant="secondary"
                className="uppercase tracking-wide text-[11px] font-semibold"
              >
                {member?.balance?.toLocaleString() || 0} points
              </Badge>
              <Badge
                variant="secondary"
                className="uppercase tracking-wide text-[11px] font-semibold"
              >
                {member?.items ? `${member.items.length} items` : "0 items"}
              </Badge>
            </div>
          )}
        </SheetDescription>
      </SheetHeader>

      <hr className="border-t border-gray-200 dark:border-slate-800 my-2 mt-0" />
      <div>
        {!view && (
          <div className="flex items-center justify-between mb-2">
            <h4 className="text-lg font-semibold">Backpack</h4>
            {!!currentMember?.permission ? (
              <button onClick={() => setView(true)}>
                <FontAwesomeIcon icon={faPlus} size="lg" />
              </button>
            ) : null}
          </div>
        )}
        {!view ? (
          isLoading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
              <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
            </div>
          ) : (member?.items.length || 0) <= 0 ? (
            <Empty type="backpack" />
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {!isLoading && selectedMember
                  ? member?.items.map((backpackItem) => {
                      const item = shop?.find((i) => i._id === backpackItem.id);
                      if (!item)
                        return <div key={backpackItem.id} className="d-none" />;
                      return (
                        <Item
                          key={item._id}
                          guildID={guildID}
                          preview
                          item={item}
                          permission={currentMember?.permission}
                          roles={guild?.roles || []}
                        />
                      );
                    })
                  : ""}
              </div>
            </>
          )
        ) : (
          <>
            <h3 className="text-lg font-semibold mb-4">Give Item</h3>
            <Select
              className="mb-2"
              onChange={(value) => setItemSelected(value[0])}
              items={
                shop
                  ?.filter((item) => {
                    const hasItem = member?.items.some(
                      (b) => b.id === item._id
                    );
                    return !hasItem && !item.expire;
                  })
                  .map((item) => {
                    return {
                      value: item._id,
                      label: item.name,
                    };
                  }) ?? []
              }
            />
            <div className="mt-auto flex gap-2 items-center">
              <button
                className="p-2 bg-brand-500 text-white rounded-lg w-full"
                onClick={() => {
                  if (!itemSelected || !selectedMember) return;
                  addItem.mutate({
                    guildID,
                    memberID: selectedMember,
                    itemID: itemSelected,
                  });
                  setView(false);
                }}
                type="submit"
              >
                Give Item
              </button>
              <button
                className="p-2 bg-park-100 dark:bg-park-700 text-white rounded-lg w-full"
                onClick={() => setView(false)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const MemberModal = () => {
  const [selectedMember, setSelectedMember] = useAtom(selectedMemberAtom);

  return (
    <Sheet
      open={!!selectedMember}
      onOpenChange={(open) => (!open ? setSelectedMember(undefined) : null)}
    >
      <SheetContent className="min-w-[690px] max-w-3xl flex flex-col">
        {selectedMember ? (
          <Member memberID={selectedMember} />
        ) : (
          <SheetHeader>
            <SheetTitle>Loading...</SheetTitle>
            <SheetDescription></SheetDescription>
          </SheetHeader>
        )}
      </SheetContent>
    </Sheet>
  );
  // return (
  //   <Slideover
  //     opened={!!selectedMember}
  //     title="Member"
  //     onClose={() => setSelectedMember(undefined)}
  //   >
  //     {selectedMember && <Member memberID={selectedMember} />}
  //   </Slideover>
  // );
};

export default MemberModal;
