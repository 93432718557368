import {
  faArrowUp,
  faChevronDoubleUp,
  faCoins,
  faDownload,
  faGears,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback } from "react";
import { APIGuild } from "@/types/points";
import { useAuthState } from "@/api/useAuthState";
import { useNavigate } from "react-router";
import { useLeaderboard } from "@/api/useLeaderboard";
import { useAtomValue, useSetAtom } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { api } from "@/config/network";
import { twMerge } from "tailwind-merge";
import ItemCreationModel from "./shop/modals/ItemCreationModal";
import { Button } from "@/components/ui/button";

type GuildHeaderProps = {
  guild?: APIGuild;
  tab: "leaderboard" | "shop" | "settings";
  permission?: "admin" | "role";
};

const GuildHeader: FC<GuildHeaderProps> = (props) => {
  const { guild, tab, permission } = props;

  const navigate = useNavigate();

  const { data: auth } = useAuthState();
  const { data: leaderboard } = useLeaderboard(guild?._id ?? "");
  const token = useAtomValue(pointsTokenAtom);

  const generateCSV = useCallback(async () => {
    if (!guild) return;
    if (!leaderboard) return;

    const response = await api.get(`/guilds/${guild._id}/leaderboard/csv`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const csvData = response.data as {
      userID: string;
      username: string;
      balance: number;
    }[];

    const csv = csvData.map((member) => {
      return `${member.userID},${member.username},${member.balance}`;
    });

    // make add header
    csv.unshift("id,username,balance");

    // download csv
    const blob = new Blob([csv.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${guild.name}_leaderboard.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [leaderboard, guild]);

  return (
    <div className="my-4 flex flex-col">
      <div className="flex flex-col mt-4">
        <div className="flex items-center gap-4">
          {guild?.icon ? (
            <img
              src={
                guild?.icon
                  ? `https://cdn.discordapp.com/icons/${guild?._id}/${guild?.icon}.png`
                  : undefined
              }
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <div className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-200 dark:bg-secondary">
              {(guild?.name?.length || 0) > 0 ? guild?.name.at(0) : ""}
            </div>
          )}
          <p className="text-lg opacity-50">
            {guild?.name || <div className="h-4 w-36" />}
          </p>
          {guild?.premium?.isActive ? (
            <Button
              variant="ghost"
              size="sm"
              className="flex items-center gap-2"
              onClick={() => navigate(`/guilds/${guild?._id}/settings`)}
            >
              <FontAwesomeIcon icon={faCoins} className="text-premium-500" />
              <span className="text-premium-500">
                Premium {guild.premium.tier === "PLUS" ? "Plus" : "Basic"}
              </span>
            </Button>
          ) : (
            <Button
              variant="ghost"
              size="sm"
              className="flex items-center gap-2"
              onClick={() => navigate(`/guilds/${guild?._id}/settings`)}
            >
              <FontAwesomeIcon
                icon={faChevronDoubleUp}
                className="text-premium-500"
              />
              <span className="text-premium-500">Upgrade to Premium</span>
            </Button>
          )}
        </div>
      </div>
      <div className="flex mt-4 items-center">
        <h2 className=" text-2xl font-bold mr-auto">
          {tab === "leaderboard"
            ? "Leaderboard"
            : tab === "shop"
              ? "Shop"
              : "Settings"}
        </h2>
        {tab === "shop" ? (
          <ItemCreationModel guildID={guild?._id ?? ""} />
        ) : tab === "leaderboard" &&
          (auth?.premium?.tier === 3 || guild?.premium?.tier === "PLUS") ? (
          <button
            className="rounded-xl mr-2 bg-brand-500 text-white py-2 px-3.5 text-sm"
            onClick={generateCSV}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-1" /> CSV (beta)
          </button>
        ) : null}
        <button
          onClick={() => navigate(`/guilds/${guild?._id}`)}
          className={twMerge(
            "rounded-xl rounded-r-none bg-primary-foreground border border-border py-2 px-3.5 text-sm",
            tab === "leaderboard" && "bg-secondary"
          )}
        >
          Leaderboard
        </button>
        <button
          onClick={() => navigate(`/guilds/${guild?._id}/shop`)}
          className={twMerge(
            "rounded-xl rounded-l-none bg-primary-foreground border border-border py-2 px-3.5 border-l-0 text-sm",
            permission === "admin" && "rounded-r-none",
            tab === "shop" && "bg-secondary"
          )}
        >
          Shop
        </button>
        {permission === "admin" ? (
          <button
            onClick={() => navigate(`/guilds/${guild?._id}/settings`)}
            className={twMerge(
              "rounded-xl rounded-l-none bg-primary-foreground border border-border py-2 px-3.5 border-l-0 text-sm",
              tab === "settings" && "bg-secondary"
            )}
          >
            <FontAwesomeIcon icon={faGears} />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default GuildHeader;
