import itemFields, { itemSchema } from "@/config/item";
import { FC, useCallback, useMemo, useState } from "react";
import { APIItem } from "@/types/points";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { twMerge } from "tailwind-merge";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@/components/ui/button";
import { useGuild } from "@/api/useGuild";
import { useAuthState } from "@/api/useAuthState";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import ImageUpload from "@/components/ImageUpload";

type ItemEditModalProps = {
  item: APIItem;
  guildID: string;
};

const ItemEditModal: FC<ItemEditModalProps> = (props) => {
  const { item, guildID } = props;

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const { data: auth } = useAuthState();
  const { data: guild } = useGuild(guildID);
  const [open, setOpen] = useState(false);

  const premiumTier = guild?.premium?.tier ? 3 : auth?.premium?.tier;

  const roles = useMemo(() => {
    return (
      guild?.roles
        .filter((role) => role.name !== "@everyone" && !role.managed)
        .map((role) => ({
          value: role._id,
          label: role.name,
        })) || []
    );
  }, [guild]);

  const form = useForm<z.infer<typeof itemSchema>>({
    resolver: zodResolver(itemSchema),
    defaultValues: {
      name: item.name,
      cost: item.cost,
      description: item.description,
      amount: item.amount,
      role: item.role ?? undefined,
      message: item.message ?? undefined,
      supply: item.supply ?? undefined,
      banner: item.banner ?? undefined,
      expire: item.expire ?? undefined,
    },
  });

  const editItem = useMutation({
    mutationFn: async ({
      guildID,
      item: itemValue,
    }: {
      guildID: string;
      item: z.infer<typeof itemSchema>;
    }) => {
      if (!token) return;
      const res = await api.patch(
        `/guilds/${guildID}/shop/${item._id}`,
        itemValue,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const handleSubmit = useCallback(
    (values: z.infer<typeof itemSchema>) => {
      editItem.mutate({ guildID, item: values });
      setOpen(false);
    },
    [guildID, editItem]
  );

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" className="h-8 w-8">
          <FontAwesomeIcon icon={faPen} />
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[690px] max-w-3xl flex flex-col overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Edit Item</SheetTitle>
          <SheetDescription>
            Edit the item below to change its properties.
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="flex flex-col gap-4"
          >
            {itemFields.map((itemField) => {
              if (!itemField?.premium || premiumTier) {
                return (
                  <FormField
                    key={itemField.id}
                    control={form.control}
                    name={itemField.id}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {itemField.name}
                          {itemField.required ? (
                            <span className="text-red-400 ml-1">*</span>
                          ) : null}
                        </FormLabel>
                        <FormDescription>
                          {itemField.description}
                        </FormDescription>
                        <FormControl>
                          {itemField.type === "string" ||
                          itemField.type === "number" ? (
                            <Input type={itemField.type} {...field} />
                          ) : itemField.type === "textarea" ? (
                            <Textarea {...field} />
                          ) : itemField.type === "select" ? (
                            <Select
                              onValueChange={field.onChange}
                              value={
                                typeof field.value === "string"
                                  ? field.value
                                  : undefined
                              }
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {roles.map((role) => (
                                  <SelectItem
                                    key={role.value}
                                    value={role.value}
                                  >
                                    {role.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          ) : itemField.type === "image" ? (
                            <ImageUpload
                              noURL
                              className="h-auto"
                              value={
                                typeof field.value === "string"
                                  ? field.value
                                  : ""
                              }
                              onChange={(value) =>
                                field.onChange({
                                  target: {
                                    value,
                                    name: field.name,
                                  },
                                })
                              }
                            />
                          ) : null}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                );
              }
              return null;
            })}
            {!premiumTier && (
              <div>
                Unlock more with premium!{" "}
                <a href="https://points.bot/premium">Learn More.</a>
              </div>
            )}
            <div className="h-4 w-full" />
            <SheetFooter className="sticky bottom-0">
              <Button
                type="submit"
                className={twMerge("p-2 rounded-lg w-full text-white")}
              >
                Edit Item
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

export default ItemEditModal;
