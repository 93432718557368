import { faCheck, faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

type Step = {
  name: string;
  description: string;
  errorCount?: number;
};

type StepperProps = {
  steps: Step[];
  currentStep: number;
  onStepClick?: (index: number) => void;
};

const Stepper: FC<StepperProps> = (props) => {
  const { steps, currentStep, onStepClick } = props;

  return (
    <div className="flex mb-4 gap-2 justify-between">
      {steps.map((step, index) => {
        const isCompleted = index < currentStep;
        const isActive = index === currentStep;
        return (
          <div
            key={step.name}
            className={twMerge(
              "flex items-center border-t-2 border-border pt-3",
              step.errorCount && isCompleted && "border-red-400",
              isCompleted && !step.errorCount && "border-brand-500"
            )}
          >
            <div
              className={twMerge(
                "w-10 h-10 flex-shrink-0 rounded-full dark:text-white flex items-center justify-center bg-primary-foreground cursor-pointer",
                !isCompleted && isActive && "border-2 border-brand-500",
                isCompleted && "bg-brand-500 text-white",
                step.errorCount && isCompleted && "bg-red-400 text-white",
                step.errorCount && isActive && "border-2 border-red-400"
              )}
              onClick={() => onStepClick?.(index)}
            >
              {isCompleted && !step.errorCount ? (
                <FontAwesomeIcon icon={faCheck} className="text-sm" />
              ) : step.errorCount && isCompleted ? (
                <FontAwesomeIcon icon={faExclamation} className="text-sm" />
              ) : (
                index + 1
              )}
            </div>
            <div className="ml-3 flex-shrink-0">
              <div className={twMerge("text-sm", !isActive && "opacity-60")}>
                {step.name}
              </div>
              <p className="text-xs font-light opacity-50">
                {step.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
