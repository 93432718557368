import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";

type ItemDeletionModalProps = {
  guildID: string;
  itemID: string;
};

const ItemDeletionModal: FC<ItemDeletionModalProps> = (props) => {
  const { guildID, itemID } = props;

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const deleteItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.delete(`/guilds/${guildID}/shop/${itemID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const hideItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}/hide`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="icon"
          variant="destructive"
          className="h-8 w-8 bg-red-500/15 text-red-500 hover:bg-red-500 hover:text-white"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogDescription>
            Would you like to delete or hide the item?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="ghost"
            onClick={() => {
              if (!itemID || !guildID) return;
              hideItem.mutate({
                guildID,
                itemID,
              });
            }}
          >
            Hide
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              if (!itemID || !guildID) return;
              deleteItem.mutate({
                guildID,
                itemID,
              });
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ItemDeletionModal;
