import { FC, useMemo } from "react";
import { SettingField } from "@/config/settings";
import SettingInput, { isInput } from "./SettingInput";
import Select from "@/components/Select";
import Toggle, { isToggle } from "./Toggle";
import ImageUpload, { isImageUpload } from "@/components/ImageUpload";
import { GuildPremiumTier } from "@/types/points";
import { canUsePremium } from "@/utils";
import { twMerge } from "tailwind-merge";
import { APIChannel, APIRole } from "discord-api-types/v10";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";

type Value = any;

type SettingCardProps = SettingField & {
  value: Value;
  isFirst?: boolean;
  items?: any[];
  secondary?: boolean;
  premiumTier?: GuildPremiumTier;
  onChange: (value: Value, changedID?: string) => void;
  onError: () => void;
};

const isSelect = (type: string): type is "select" =>
  type === "channel" || type === "role";

const isRole = (item: APIRole | APIChannel, type: string): item is APIRole =>
  type === "role";

const isChannel = (
  item: APIRole | APIChannel,
  type: string
): item is APIChannel => type === "channel";

const SettingCard: FC<SettingCardProps> = (props) => {
  const {
    name,
    error,
    vaildate,
    id,
    type,
    fields,
    secondary,
    premium,
    items,
    value,
    description,
    premiumTier,
    isFirst,
    onError,
    onChange,
  } = props;

  const isPremium = useMemo(
    () => canUsePremium(premium, premiumTier),
    [premium, premiumTier]
  );

  const selectItems = useMemo(() => {
    if (items?.length) {
      return items
        .filter((item) => {
          if (isRole(item, type)) {
            if (item.name === "@everyone" || item.managed) return false;
            return true;
          } else if (isChannel(item, type)) {
            if (item.type !== 0 && item.type !== 5) return false;
            return true;
          }
        })
        .map((item) => {
          return {
            value: item._id,
            label: item.name || item._id,
          };
        });
    }
    return [];
  }, []);

  if (secondary) {
    return (
      <div className="flex flex-col gap-0">
        {!!isToggle(type) ? (
          <>
            <div className="flex justify-between">
              <h4 className={twMerge(isFirst ? "font-bold" : "")}>{name}</h4>
              {/* <Toggle id={id} checked={value} onChange={onChange} /> */}
              <Switch
                checked={value}
                onCheckedChange={(v) => onChange(v, id)}
              />
            </div>

            <p className="text-xs mt-1 opacity-70">{description}</p>
          </>
        ) : (
          <h4
            className={twMerge("opacity-80", isFirst ? "font-bold" : "text-xs")}
          >
            {name}
          </h4>
        )}
        <div
          className={cn(
            "mt-1 flex flex-col gap-2",
            !!isImageUpload(type) ? "h-full" : null
          )}
        >
          {!!isInput(type) ? (
            <SettingInput
              id={id}
              type={type}
              description={description}
              vaildate={vaildate}
              error={error}
              disabled={!isPremium}
              value={value}
              onError={onError}
              onChange={onChange}
            />
          ) : null}
          {!!isSelect(type) ? (
            <Select
              items={selectItems || []}
              value={value}
              disabled={!isPremium}
              onChange={onChange}
              description={description}
              multiple
            />
          ) : null}
          {!!isImageUpload(type) ? (
            <ImageUpload
              value={value}
              disabled={!isPremium}
              onChange={onChange}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        "rounded-xl p-4 relative shadow-xl bg-primary-foreground border border-border flex flex-col",
        premium && "border-premium-500 dark:border-premium-500"
      )}
    >
      {!!isToggle(type) ? (
        <>
          <div className="flex items-center justify-between">
            <h5 className="font-bold">{name}</h5>
            <Toggle id={id} checked={value} onChange={onChange} />
          </div>
          <p className="font-xs opacity-70 mt-1">{description}</p>
        </>
      ) : (
        <div className="flex items-center justify-between">
          <h5 className="font-bold">{name}</h5>
          {premium ? (
            <div className="uppercase tracking-wider font-semibold flex items-center justify-center rounded-full px-2 py-1 text-xs absolute -top-2.5 -right-2.5 shadow-xl bg-premium-500 text-white">
              {premium}
            </div>
          ) : null}
        </div>
      )}
      <div
        className={cn(
          "flex flex-col gap-1",
          !!isImageUpload(type) ? "flex-grow" : null
        )}
      >
        {!!isInput(type) ? (
          <SettingInput
            id={id}
            type={type}
            description={description}
            vaildate={vaildate}
            error={error}
            disabled={!isPremium}
            value={value}
            onError={onError}
            onChange={onChange}
          />
        ) : null}
        {!!isSelect(type) ? (
          <Select
            items={selectItems || []}
            value={value}
            onChange={onChange}
            multiple
            description={description}
          />
        ) : null}
        {!!isImageUpload(type) ? (
          <ImageUpload
            value={value}
            disabled={!isPremium}
            onChange={onChange}
            className="mt-2"
          />
        ) : null}
        {fields?.length ? (
          <div className="flex flex-col gap-2 mt-1">
            {fields?.map((item, index) => {
              const fieldID = item.id.split(".");
              const lastField = fieldID[fieldID.length - 1];
              const finalValue =
                lastField in (value || {}) ? value[lastField] : null;
              return (
                <SettingCard
                  key={index}
                  isFirst={index === 0}
                  secondary
                  items={items}
                  premiumTier={premiumTier}
                  premium={premium}
                  value={finalValue}
                  {...item}
                  onChange={(v) => onChange(v, item.id)}
                  onError={onError}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SettingCard;
