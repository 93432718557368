import Navbar from "@/components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faCoins, faStore } from "@fortawesome/pro-solid-svg-icons";
import Footer from "@/components/Footer";
import { useMedia } from "react-use";

const featureCards = [
  {
    id: "message-points",
    title: "Message Points",
    icon: faCoins,
    description:
      "Earn 0-3 points per message sent or more with points multiplier. Don't like this? Disable it!",
    image: "/images/other/MessagePointsDefault.png",
  },
  {
    id: "rewards",
    title: "Rewards",
    icon: faCogs,
    description:
      "Need some points? Collect a points everyday by running the /daily command.",
    image: "/images/other/Rewards.png",
  },
  {
    id: "shop",
    title: "Shop",
    icon: faStore,
    description:
      "Using your hard earned points you can buy items that are stocked by your server moderators.",
    image: "/images/other/Shop.png",
  },
];

const Home = () => {
  const isDarkMode = useMedia("(prefers-color-scheme: dark)");
  return (
    <>
      <div
        className="w-full max-h-screen overflow-hidden"
        style={{
          background: isDarkMode
            ? "linear-gradient(180deg, #020617 0%, #28ACFF 99%)"
            : "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%,rgba(235, 252, 255, 1) 100%)",
        }}
      >
        <Navbar />
        <div className="container mx-auto pt-20">
          <div className="flex flex-col items-center text-center">
            <h1 className="font-extrabold text-5xl mb-8">
              Enriching communities
              <br />
              with economies.
            </h1>
            <h4 className="text-2xl font-light opacity-80 mb-4">
              A fully customizable economy Discord bot offering many
              <br />
              features while remaining as simple and intuitive as possible.
            </h4>
          </div>
          <img
            src="/images/other/DiscordDefault.svg"
            alt="discord"
            className="mt-4 w-full max-h-[400px] object-cover object-top"
          />
        </div>
      </div>
      <div className="container mx-auto pt-8">
        <div className="my-6">
          <div className="uppercase opacity-50 mb-1">
            Building your community
          </div>
          <h3 className="font-bold text-xl mb-3">
            Points offers lots of options to help grow your community.{" "}
          </h3>
          <p className="opacity-80">
            Reward members for being active and participating in your server
            through message points. Host your own economy through our banking
            system. Try your luck with dice and coin flip commands. Give out
            cool rewards with the shop. And so much more.
          </p>
        </div>
        <div className="mt-8" id="features">
          <h5 className="text-3xl font-bold mb-4">Features</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {featureCards.map((feature) => (
              <div
                key={feature.id}
                className="rounded-xl overflow-hidden shadow-xl bg-primary-foreground border border-border"
                // transition-colors duration-300 ease-in-out hover:bg-park-100 dark:hover:bg-park-800 hover:border-park-200 dark:hover:border-park-700
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className=" w-full h-full max-h-[200px] rounded-t-xl object-cover object-center"
                />
                <div className="p-4">
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={feature.icon} />
                    <h4 className="ml-2 font-bold">{feature.title}</h4>
                  </div>
                  <p className="opacity-80">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto py-8">
        <div className="py-8">
          <h2 className="mb-4  text-4xl">
            Get more customization options
            <br />
            with <strong>Points Premium</strong>
          </h2>

          <p className="opacity-50 text-lg">
            Points Premium offers more customization options and features to
            help you build your community.
          </p>

          {/* list of features */}
          <ul className="mt-4 flex flex-col gap-2 list-disc list-outside -mr-4 pl-4">
            <li>
              <strong>Enhanced Item Capacity</strong> - Manage a vibrant economy
              with up to 200 items.
            </li>
            <li>
              <strong>Exclusive Rewards System</strong> - Unlock weekly,
              monthly, and yearly reward commands to keep your community
              engaged.
            </li>
            <li>
              <strong>Currency Craft</strong> - Set your unique currency name,
              giving your server its distinct identity. flip.
            </li>
            <li>
              <strong>Balance & Boundaries</strong> - Tailor your economy with
              customized settings: Set reward caps, Starting balances for
              newcomers. Control the economy's flow with custom multipliers.
              with the shop.
            </li>
            <li>
              <strong>Aesthetic Architect</strong> - Style your server's economy
              to your taste by adjusting embed designs.
            </li>
          </ul>

          <button
            className="mt-8 py-3 w-full rounded-2xl bg-premium-500 font-bold text-white transition-colors duration-300 ease-in-out hover:bg-premium-600"
            onClick={() => {
              window.location.href =
                "https://discord.com/application-directory/484395597508509697/premium";
            }}
          >
            Subscribe on Discord
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
