import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { pointsTokenAtom } from "@/stores";
import {
  APIBalance,
  APIBackpack,
  APIReward,
  APIMember,
  APIGuild,
  APIUser,
  APIPremium,
} from "@/types/points";
import { parseAvatar } from "@/utils";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

type BulkGuildBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  guild?: APIGuild;
};

type BulkUserBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  user?: APIUser;
  premium?: APIPremium;
};

const GuildContent: FC<BulkGuildBlob> = (props) => {
  const { guild, members, balances } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <img
          src={parseAvatar(guild?._id, guild?.icon, 32)}
          alt="Avatar"
          className="rounded-full"
        />
        <h4 className="font-bold">{guild?.name}</h4>
      </div>
      <br />
      <div>
        <p className="font-bold mb-1">Members</p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Guild ID</TableHead>
              <TableHead>Joined At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {members?.map((member) => (
              <TableRow key={member.userID}>
                <TableCell>{member.userID}</TableCell>
                <TableCell>{member.joinedAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        <p className="font-bold mb-1">Balances</p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Guild ID</TableHead>
              <TableHead>Balance</TableHead>
              <TableHead>Username</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {balances?.map((balance) => (
              <TableRow key={balance.userID}>
                <TableCell>{balance.userID}</TableCell>
                <TableCell>{balance.balance}</TableCell>
                <TableCell>{balance.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const GuildLookup = () => {
  const [editingGuildID, setEditingGuildID] = useState<string>();

  const [guild, setGuild] = useState<BulkGuildBlob>();
  const token = useAtomValue(pointsTokenAtom);

  return (
    <>
      <form
        className="mt-4"
        onSubmit={async (event) => {
          event.preventDefault();
          if (!editingGuildID) return;
          const { data } = await api.get<BulkUserBlob>(
            `/admin/guilds/${editingGuildID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setGuild(data);
        }}
      >
        <Input
          className="w-full p-2 rounded-lg"
          placeholder="Guild ID"
          value={editingGuildID}
          onChange={(event) => setEditingGuildID(event.target.value)}
        />
      </form>
      {guild && <GuildContent {...guild} />}
    </>
  );
};

export default GuildLookup;
