// import "@fontsource/inter/100.css"; // Thin
// import "@fontsource/inter/200.css"; // Extra Light
// import "@fontsource/inter/300.css"; // Light
// import "@fontsource/inter/400.css"; // Regular
// import "@fontsource/inter/500.css"; // Medium
// import "@fontsource/inter/600.css"; // Semi Bold
// import "@fontsource/inter/700.css"; // Bold
// import "@fontsource/inter/800.css"; // Extra Bold
// import "@fontsource/inter/900.css"; // Black
import "./index.css";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  useNavigate,
  Routes,
  useParams,
} from "react-router-dom";
import Loading from "./components/Loading";
import React, { FC, Suspense, useEffect } from "react";
import { useQuery } from "./utils";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Body from "./components/Body";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RequireAuth from "./components/RequireAuth";
import Admin from "./pages/admin";
import Leaderboard from "./pages/guild/leaderboard";
import Shop from "./pages/guild/shop";
import Commands from "./pages/guild/settings/commands";
import Customization from "./pages/guild/settings/customization";
import DocsCommands from "./pages/docs/commands";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Redirect from "./pages/redirect";
import Guilds from "./pages/guild/index";
import { useAuthState } from "./api/useAuthState";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Docs from "@/pages/docs";
import { useSetAtom } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { ThemeProvider } from "./components/theme-provider";
import { Toaster } from "@/components/ui/toaster";

Sentry.init({
  dsn: "https://d627db94f8dd418fa4b02c3a4e2eaa7c@sentry.io/1529686",
});

const RedirectToInvite = () => {
  const { guildID } = useParams();
  return (
    <Redirect
      url={`https://discord.com/api/oauth2/authorize?client_id=484395597508509697&permissions=403176657&scope=bot%20applications.commands&guild_id=${guildID}`}
    />
  );
};

const App: FC = () => {
  const { data: auth } = useAuthState();
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const setToken = useSetAtom(pointsTokenAtom);

  useEffect(() => {
    if (window.location.host.includes("points.city")) {
      window.location.href = window.location.href.replace(
        "points.city",
        "points.bot"
      );
    }
  }, []);

  useEffect(() => {
    if (query) {
      const qt = query.get("ut");
      if (!qt) return;
      setToken(qt);
      if (location.pathname.includes("/_redirect")) {
        navigate("/guilds", { replace: true });
        return;
      }
      navigate(location.pathname, { replace: true });
    }
  }, [query, location]);

  useEffect(() => {
    if (!!auth?.premium?.tier) {
      document.body.style.setProperty("--primary", "#f0ab00");
      document.body.style.setProperty("--primaryDark", "#CF9507");
    }
  }, [auth]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Redirect />} path="/_redirect" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Docs />} path="/docs/*" />
        <Route element={<DocsCommands />} path="/commands" />
        <Route
          element={<Redirect url="https://discord.gg/GHesmbU" />}
          path="/discord"
        />
        <Route
          element={
            <Redirect url="https://discord.com/application-directory/484395597508509697/store/1162121253923074159" />
          }
          path="/patreon"
        />
        <Route
          element={
            <Redirect url="https://discord.com/application-directory/484395597508509697/store/1162121253923074159" />
          }
          path="/premium"
        />
        <Route
          element={<Redirect url="https://twitter.com/PointsCity" />}
          path="/twitter"
        />
        <Route
          element={
            <Redirect url="https://discord.com/api/oauth2/authorize?client_id=484395597508509697&permissions=403176657&scope=bot%20applications.commands" />
          }
          path="/invite"
        />
        <Route element={<RedirectToInvite />} path="/invite/:guildID" />
        <Route
          element={
            <RequireAuth permission="admin">
              <Admin />
            </RequireAuth>
          }
          path="/admin/*"
        />
        <Route
          element={
            <RequireAuth>
              <Guilds />
            </RequireAuth>
          }
          path="/guilds"
        />
        <Route
          element={
            <RequireAuth>
              <Leaderboard />
            </RequireAuth>
          }
          path="/guilds/:guildID"
        />
        <Route
          element={
            <RequireAuth>
              <Shop />
            </RequireAuth>
          }
          path="/guilds/:guildID/shop"
        />
        <Route
          element={
            <RequireAuth>
              <Customization />
              <Toaster />
            </RequireAuth>
          }
          path="/guilds/:guildID/settings"
        />
        <Route
          element={
            <RequireAuth>
              <Commands />
              <Toaster />
            </RequireAuth>
          }
          path="/guilds/:guildID/settings/commands"
        />
        <Route
          path="*"
          element={
            <>
              <Navbar />
              <Body className="flex flex-col">
                <div className="flex flex-col items-center justify-center flex-grow h-full relative">
                  <h1 className="text-9xl font-bold absolute top-[35%] text-gray-300">
                    404
                  </h1>
                  <h1 className="text-9xl font-bold absolute top-1/3">404</h1>
                </div>
              </Body>
              <Footer />
            </>
          }
        />
      </Routes>
    </Suspense>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = document.getElementById("root")!;

ReactDOM.createRoot(root).render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <ThemeProvider defaultTheme="system" storageKey="points-ui-theme">
        <App />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Router>
  </QueryClientProvider>
);
